
// Vue
import { computed, ref, watch } from 'vue';

// icons
import { logOutOutline, createOutline, keyOutline, camera, globe, moon, receipt, newspaper, heart, cardOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonSpinner, IonList, IonItem, IonLabel,
        IonIcon, IonBackButton, IonButton, IonNote, IonImg,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
        IonSelect, IonSelectOption, IonBadge,
        alertController, modalController, loadingController, } from '@ionic/vue';
import UserProfileFormModal from '@/components/modals/UserProfileFormModal.vue';
import PageHeader from "@/components/PageHeader.vue";

// API services
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';

// utils or methods
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import config from '@/config';

// Capacitor
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

export default {
  name: 'UserProfilePage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonRow, IonCol, IonSpinner, IonList, IonItem, IonLabel,
                IonIcon, IonBackButton, IonButton, IonNote, IonImg,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonSelect, IonSelectOption, IonBadge,
                PageHeader, },
  setup() {
    const store = useStore();
    
    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingUser);
    const user = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    
    // methods or filters
    const { t, locale } = useI18n();
    const { presentToast, presentPrompt, } = utils();

    const doLogout = async () => {
      const alert = await alertController.create({
        header: t('UserProfilePage.logout'),
        message: t('UserProfilePage.confirmLogout'),
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('UserProfilePage.logout'),
            handler: () => {
              AuthService.doLogout();
            }
          }
        ]
      });
      return alert.present();
    }
    const presentChangePasswordPrompt = async () => {
      const alert = await alertController.create({
        header: t('UserProfilePage.changePassword'),
        inputs: [
          {
            name: 'oldPassword',
            type: 'password',
            placeholder: t('UserProfilePage.oldPassword')
          },
          {
            name: 'newPassword',
            type: 'password',
            placeholder: t('UserProfilePage.newPassword')
          },
          {
            name: 'newPasswordConfirm',
            type: 'password',
            placeholder: t('UserProfilePage.newPasswordConfirm')
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('confirm'),
            handler: (value) => {
              if (value.oldPassword && value.newPassword && value.newPasswordConfirm) {
                if (value.newPassword != value.newPasswordConfirm) {
                  presentToast(t('inconsistentNewPasswordMsg'));
                } else {
                  AuthService.updatePassword(value.oldPassword, value.newPassword).then(res => {
                    if (res == 'wrong-password') {
                      presentToast(t('wrongOldPassword'));
                    } else {
                      presentToast(t('successUpdatePassword'), 3000, 'top');
                      alert.dismiss();
                    }
                  });
                }
              } else {
                presentToast(t('enterAllFields'));
              }
              return false;
            }
          }
        ]
      });
      await alert.present();
    }
    
    // update user information like first name and last name
    const openEditUserProfileModal = async () => {
      const modal = await modalController.create({
        component: UserProfileFormModal,
        componentProps: { editingUser: user },
      });
      return modal.present();
    }

    // Update user profile picture (Capacitor Camera)
    const updateProfilePic = async () => {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        quality: 60,
        width: 1500,
      });
      const loading = await loadingController.create({});
      await loading.present();
      const fileName = `${moment().format('YYYYMMDDHHmmss')}-${user.value.username}.${cameraPhoto.format}`;
      UserService.updateLoggedInUserProfilePic(cameraPhoto.dataUrl, fileName, user.value.profilePic);
      store.commit('updateUser', { profilePic: cameraPhoto.dataUrl });
      loading.dismiss();
      presentToast(t("UserProfilePage.successChangeProfilePhoto"), 3000, 'top');
    }

    const deleteAccount = async () => {
      presentPrompt(t('deleteAccount'), t('confirmDeleteAccountMsg'), async () => {
        const loading = await loadingController.create({});
        await loading.present();
        await UserService.deleteLoggedInUser();
        loading.dismiss();

        AuthService.doLogout();
        presentToast(t('accountDeleted'), 3000);
      });
    }

    watch(locale, (currLocale, prevLocale) => {
      if (user.value.id) { // only for logged in users
        UserService.updateUserAppLanguage(currLocale);
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      t, locale,

      // icons
      logOutOutline, createOutline, keyOutline, camera, globe, moon, receipt, newspaper, heart, cardOutline,

      // variables
      loading, user, userLoggedIn,
      versionCode: config.versionCode,

      // methods
      doLogout, presentChangePasswordPrompt, openEditUserProfileModal, updateProfilePic,
      deleteAccount,
    }
  },
}
